import React, { Component } from 'react';
import { MailComponent, SupportEmail } from '../../data/contact-info';

// Component main
export default class ContactUsPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2>Contact Us</h2>
                <h4>Universica</h4>
                <p>
                    If you are having issues with Universica, visit the <a href="https://github.com/StudioKoleman/Universica/issues">GitHub Issues Page</a><br/>
                    to directly submit a bug report or feature request.<br/>
                </p>
                <h4>Email</h4>
                <p>
                    Are you having problems with any of our apps and/or games?<br/>
                    Do you have any suggestions about improving our apps and/or games?<br/>
                    Contact us using the following email address:<br/>
                </p>
                <h6>email: {SupportEmail}</h6>
                <p>
                    Please also indicate in the subject line of the email what app and/or game you are experiencing trouble with.
                </p>
                <h4>Mail</h4>
                <p>
                    Feel free to also contact us by mail at:
                </p>
                <MailComponent/>
            </div>
        )
    }
}