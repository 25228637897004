import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MailComponent, SupportEmail } from '../../data/contact-info';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class PrivacyPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2><b>Privacy Notice</b></h2>
                <h6>Last updated July 21, 2022</h6>
                <br />
                <p>
                    Thank you for choosing to be part of our community at Studio Koleman ("<b>Company</b>," "<b>we</b>," "<b>us</b>," "<b>our</b>").
                    We are committed to protecting your personal information and your right to privacy.
                    If you have any questions about this privacy notice or our practices with regard to your personal information, please contact {SupportEmail}.
                </p>
                <br />
                <p>
                    This privacy notice describes how we might use your information if you:
                    <ul class="ul-classic">
                        <li class="li-classic"> • Download and use any of our mobile applications - including but not limited to Universica, Skyward, Forever After Death, and Buckethead 4</li>
                        <li class="li-classic"> • Engage with us in other related ways - including any sales, marketing, or events</li>
                    </ul>
                </p>
                <br />
                <p>
                    In this privacy notice, if we refer to:
                    <ul class="ul-classic">
                        <li class="li-classic"> • "<b>App</b>," we are referring to any applications of ours that link to this policy, including any listed above</li>
                        <li class="li-classic"> • "<b>Services</b>," we are referring to our App, and other related services, including any sales, marketing, or events</li>
                    </ul>
                </p>
                <br />
                <p>
                    The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it.
                    If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
                </p>
                <br />
                <p><b>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</b></p>
                <br />

                <h4><b>TABLE OF CONTENTS</b></h4>
                <br />
                <p>
                    <a href="#information_collection">1. WHAT INFORMATION DO WE COLLECT?</a><br />
                    <a href="#information_usage">2. HOW DO WE USE YOUR INFORMATION?</a><br />
                    <a href="#information_sharing">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a><br />
                    <a href="#information_international_transfer">4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a><br />
                    <a href="#third-party">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a><br />
                    <a href="#information_storage">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br />
                    <a href="#information_security">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a><br />
                    <a href="#privacy-rights">8. WHAT ARE YOUR PRIVACY RIGHTS?</a><br />
                    <a href="#do-not-track">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a><br />
                    <a href="#california-notice">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a><br />
                    <a href="#notice-updates">11. DO WE MAKE UPDATES TO THIS NOTICE?</a><br />
                    <a href="#notice-contact">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a><br />
                </p>
                <br />

                <a id="information_collection"><h4><b>1. WHAT INFORMATION DO WE COLLECT?</b></h4></a>
                <br />
                <h5><b>Information automatically collected</b></h5>
                <br />
                <p><i><b>In Short:</b> Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics - may be collected when you visit our App.</i></p>
                <br />
                <p>
                    We automatically collect certain information when you visit, use or navigate the App.
                    This information does not reveal your specific identity (like your name or contact information)
                    but may include device and usage information, such as your IP address, browser and device characteristics, operating system,
                    language preferences, referring URLs, device name, country, location, information about how and when you use our App and other technical information.
                    This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.
                </p>
                <br />
                <p>
                    The information we collect includes:
                    <ul class="ul-classic">
                        <li class="li-classic"> • "
                            <i>Log and Usage Data</i>." Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect
                            when you access or use our App and which we record in log files.
                            Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information
                            about your activity in the App (such as date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as
                            which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
                        </li>
                    </ul>
                </p>
                <br />

                <h5><b>Information collected through our App</b></h5>
                <br />
                <p><i><b>In Short:</b> We collect information regarding your mobile device, push notifications, and when you use our App.</i></p>
                <br />
                <p>
                    If you use our App, we also collect the following information:
                    <ul class="ul-classic">
                        <li class="li-classic"> • "
                            <i>Mobile Device Access</i>." We may request access or permission to certain features from your mobile device, including your mobile device's storage,
                            and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                        </li>
                        <li class="li-classic"> • "
                            <i>Push Notifications</i>." We may request to send you push notifications regarding your account or certain features of the App. If you wish to
                            opt-out from receiving these types of communications, you may turn them off in your device's settings.
                        </li>
                    </ul>
                    <br />
                    This information is primarily needed to maintain security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
                </p>
                <br />

                <a id="information_usage"><h4><b>2. HOW DO WE USE YOUR INFORMATION?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</i></p>
                <br />
                <p>
                    We use personal information collected via our App for a variety of business purposes described below.
                    We process your personal information for these purposes in reliance on our legitimate business interests,
                    in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
                    We indicate the specific processing grounds we rely on next to each purpose listed below.
                </p>
                <br />
                <p>
                    We use the information we collect or receive:
                    <ul class="ul-classic">
                        <li class="li-classic">
                            <b> • To facilitate the account creation and logon process.</b> If you choose to link your account with us to a third-party account
                            (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate
                            the account creation and logon process for performance of the contract.
                        </li>
                        <li class="li-classic">
                            <b> • To post testimonials.</b> We post testimonials on our App that may contain personal information.
                            Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial.
                            If you wish to update, or delete your testimonial, please contact us at help.studiokoleman@gmail.com and be sure to
                            include your name, testimonial location, and contact information.
                        </li>
                        <li class="li-classic">
                            <b> • Request feedback.</b> We may use your information to request feedback and to contact you about your use of our App.
                        </li>
                        <li class="li-classic">
                            <b> • To enable user-to-user communications.</b> We may use your information in order to enable user-to-user communications
                            with each user's consent.
                        </li>
                        <li class="li-classic">
                            <b> • To manage user accounts.</b> We may use your information for the purposes of managing our account and keeping it in
                            working order.
                        </li>
                        <li class="li-classic">
                            <b> • To send administrative information to you.</b> We may use your personal information to send you product, service and
                            new feature information and/or information about changes to our terms, conditions, and policies.
                        </li>
                        <li class="li-classic">
                            <b> • To protect our Services.</b> We may use your information as part of our efforts to keep our App safe and secure (for
                            example, for fraud monitoring and prevention).
                        </li>
                        <li class="li-classic">
                            <b> • To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
                                requirements or in connection with our contract.</b>
                        </li>
                        <li class="li-classic">
                            <b> • To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal request, we may need to
                            inspect the data we hold to determine how to respond.
                        </li>
                    </ul>
                </p>
                <br />

                <a id="information_sharing"><h4><b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with services,
                    to protect your rights, or to fulfill business obligations.</i></p>
                <br />
                <p>
                    We may process or share your data that we hold based on the following legal basis:
                    <ul class="ul-classic">
                        <li class="li-classic">
                            <b> • Consent:</b> We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                        </li>
                        <li class="li-classic">
                            <b> • Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                        </li>
                        <li class="li-classic">
                            <b> • Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal information
                            to fulfill the terms of our contract.
                        </li>
                        <li class="li-classic">
                            <b> • Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to comply with
                            applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court
                            order or a subpoena (including in response to public authorities to meet national security or law enforcement
                            requirements).
                        </li>
                        <li class="li-classic">
                            <b> • Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate, prevent, or take action
                            regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any
                            person and illegal activities, or as evidence in litigation in which we are involved.
                        </li>
                    </ul>
                    More specifically, we may need to process your data or share your personal information in the following situations:
                    <ul class="ul-classic">
                        <li class="li-classic">
                            <b> • Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger,
                            sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                    </ul>
                </p>
                <br />

                <a id="information_international_transfer"><h4><b>4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We may transfer, store, and process your information in countries other than your own.</i></p>
                <br />
                <p>
                    Our servers are located in the United States of America (the US). If you are accessing our App from outside the US, please be aware that your information may be transferred
                    to, stored, and processed by us in our US facilities and by those third parties with whom we may share your personal information
                    (see "<a href="#information_sharing">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>" above), in the US and other countries.
                </p>
                <br />
                <p>
                    If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily
                    have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary
                    measures to protect your personal information in accordance with this privacy notice and applicable law.
                </p>
                <br />

                <a id="third-party"><h4><b>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our Website.</i></p>
                <br />
                <p>
                    The App may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online
                    services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data
                    collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security
                    practices and policies of any third parties, including other websites, services or applications that may be linked to or from the
                    App. You should review the policies of such third parties and contact them directly to respond to your questions.
                </p>
                <br />

                <a id="information_storage"><h4><b>6. HOW LONG DO WE KEEP YOUR INFORMATION?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</i></p>
                <br />
                <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a
                    longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this
                    notice will require us keeping your personal information for longer than 90 days.
                </p>
                <br />
                <p>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize
                    such information, or, if this is not possible (for example, because your personal information has been stored in backup archives),
                    then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                </p>
                <br />

                <a id="information_security"><h4><b>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</i></p>
                <br />
                <p>
                    We have implemented appropriate technical and organizational security measures designed to protect the security of any
                    personal information we process. However, despite our safeguards and efforts to secure your information, no electronic
                    transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly
                    collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission
                    of personal information to and from our App is at your own risk. You should only access the App within a secure environment.
                </p>
                <br />

                <a id="privacy-rights"><h4><b>8. WHAT ARE YOUR PRIVACY RIGHTS?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> You may review, change, or terminate your account at any time.</i></p>
                <br />
                <p>
                    If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the
                    right to complain to your local data protection supervisory authority. You can find their contact
                    details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                </p>
                <br />
                <p>
                    If you are a resident in Switzerland, the contact details for the data protection authorities are
                    available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
                </p>
                <br />

                <a id="do-not-track"><h4><b>9. CONTROLS FOR DO-NOT-TRACK FEATURES</b></h4></a>
                <br />
                <p>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or
                    setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and
                    collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As
                    such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice
                    not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                </p>
                <br />

                <a id="california-notice"><h4><b>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</i></p>
                <br />
                <p>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to
                    request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we
                    disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared
                    personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a
                    request, please submit your request in writing to us using the contact information provided below.
                </p>
                <br />
                <p>
                    If you are under 18 years of age, reside in California, and have a registered account with the App, you have the right to request
                    removal of unwanted data that you publicly post on the App. To request removal of such data, please contact us using the
                    contact information provided below, and include the email address associated with your account and a statement that you reside
                    in California. We will make sure the data is not publicly displayed on the App, but please be aware that the data may not be
                    completely or comprehensively removed from all our systems (e.g. backups, etc.).
                </p>

                <a id="notice-updates"><h4><b>11. DO WE MAKE UPDATES TO THIS NOTICE?</b></h4></a>
                <br />
                <p><i><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></p>
                <br />
                <p>
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the
                    updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify
                    you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review
                    this privacy notice frequently to be informed of how we are protecting your information.
                </p>
                <br />

                <a id="notice-contact"><h4><b>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b></h4></a>
                <br />
                <p>If you have questions or comments about this notice, You can contact us:</p>
                <ul>
                    <li>
                        <p>By email: {SupportEmail}</p>
                    </li>
                    <li>
                        <p>By visiting our <Link to="/contact-us">contact us</Link> page</p>
                    </li>
                    <li>
                        <p>By mail:</p>
                        <MailComponent/>
                    </li>
                </ul>
            </div>
        )
    }
}