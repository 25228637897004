import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Component main
export default class FAQPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2>FAQ</h2>
                <h4><b>Studio Koleman</b></h4>
                <p>This website is currently being improved upon and updated every day, and does not have all the features we wish for it to one day have. 
                    In particular, we plan to bring much more helpful frequently asked questions to this page to provide the fastest and best help to anyone experiencing any difficulties with our apps and/or games.</p>
                <br/>

                <h4><b>Universica</b></h4>
                <p>Q: All the controls work but I can't move the ship</p>
                <p>A: This is a known problem that only affects very few devices and is currently being worked on. 
                    In the meantime, try switching the input mode in settings to "Alternate."</p>
                <br/>

                <h4><b>Skyward</b></h4>
                <p>Q: I can't find the exit to a level</p>
                <p>A: The exit to most dungeon levels is almost always as far away from the center of the dungeon as possible.</p>
                <br/>

                <h4><b>Forever After Death</b></h4>
                <p>Q: Too many enemies are spawning at once</p>
                <p>A: We are always working on balancing our games and making them feel fair but challenging. 
                    We are quite happy with the challenge of early levels but have not gotten later levels to feel quite right.
                    If you're struggling you probably made it further in the game than we did!</p>
                <br/>

                <h4><b>Buckethead 4</b></h4>
                <p>Q: I'm stuck on level 2 and the diagram isn't helping</p>
                <p>A: To get the greatest push or pull from a magnet, make sure your electromagnet is off until you're as close to the object as possible.</p>
                <br/>

                <h4><b>Contact Us</b></h4>
                <p>Still can't find the answer you are looking for? Feel free to reach out to us through 
                    our <Link to="/contact-us">contact us</Link> page.</p>
            </div>
        )
    }
}