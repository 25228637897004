import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { menuData } from '../../data/menu-data';
import MenuItems from "./menu-items"
import brandImg from "../../images/StudioKoleman.png";

// Component main
export default class Navbar extends Component {
    render() {
        const depthLevel = 0;
        return (
            <nav className="navbar navbar-custom-top navbar-expand-lg" style={{ minHeight: "80px", padding: "0px" }}>
                <ul className="menus">
                    <li className="menu-items">
                        <button type="button" style={{ width: "92px", height: "80px" }}>
                            <Link to="/">
                                <img width="64px"
                                    height="64px"
                                    src={brandImg}
                                    style={{ position: "relative", borderRadius: "10px" }}
                                    alt="Studio Koleman logo"
                                />
                            </Link>
                        </button>
                    </li>
                    {menuData.map((menu, index) => {
                        return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
                    })}
                </ul>
                
            </nav>
        )
    }
}

/*
<ul className="mr-auto">
                    <li className="navbar-custom-item" style={{ paddingLeft: "0px" }}>
                        <Link to="/" className="navbar-custom-link">
                            <img width="64px"
                                height="64px"
                                src={brandImg}
                                style={{ position: "relative", top: "-3px", left: "0px", right: "0px" }}
                                alt="Studio Koleman logo"
                            />
                        </Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/" className="navbar-custom-link">Home</Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/privacy" className="navbar-custom-link">Privacy</Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/terms-of-service" className="navbar-custom-link">Terms of Service</Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/contact-us" className="navbar-custom-link">Contact Us</Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/universica" className="navbar-custom-link">
                            <img width="60px"
                                height="60px"
                                src="./assets/images/games/UniversicaIcon512.png"
                                style={{ position: "relative" }}
                                alt="Universica"
                            />
                        </Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/skyward" className="navbar-custom-link">
                            <img width="60px"
                                height="60px"
                                src="./assets/images/games/SkywardIcon512.png"
                                style={{ position: "relative" }}
                                alt="Skwyard"
                            />
                        </Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/forever-after-death" className="navbar-custom-link">
                            <img width="60px"
                                height="60px"
                                src="./assets/images/games/ForeverAfterDeathIcon512.png"
                                style={{ position: "relative" }}
                                alt="Forever After Death"
                            />
                        </Link>
                    </li>
                    <li className="navbar-custom-item">
                        <Link to="/buckethead4" className="navbar-custom-link">
                            <img width="60px"
                                height="60px"
                                src="./assets/images/games/Buckethead4Icon512.png"
                                style={{ position: "relative" }}
                                alt="Buckethead 4"
                            />
                        </Link>
                    </li>
                </ul>
*/