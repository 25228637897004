export const linkedIns = [
    {
        name: "Kristian Kolehmainen",
        vanity: "manukolehmainen",
        role: "CEO &\n Lead Programmer",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/manukolehmainen?trk=profile-badge"
    },
    {
        name: "Brooks Boyer",
        vanity: "brooks-boyer-ab12b6129",
        role: "Lead Creative Director",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/brooks-boyer-ab12b6129?trk=profile-badge"
    },
    {
        name: "Bryan Morris",
        vanity: "bryan-anthony-morris",
        role: "Lead Artist",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/bryan-anthony-morris?trk=profile-badge"
    },
    {
        name: "Robert Kilkenny",
        vanity: "robert-kilkenny3",
        role: "Lead Programmer",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/robert-kilkenny3?trk=profile-badge"
    },
    {
        name: "Alexander Dvorak",
        vanity: "alexander-dvorak-3b4737221",
        role: "Game Designer",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/alexander-dvorak-3b4737221?trk=profile-badge"
    },
    {
        name: "Nathan Whelden",
        vanity: "nathanwhelden",
        role: "Programmer",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/nathanwhelden?trk=profile-badge"
    },
    {
        name: "Ben Weiss",
        vanity: "ben-weiss42",
        role: "Junior Programmer",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/ben-weiss42?trk=profile-badge"
    },
    {
        name: "Brandon Teta",
        vanity: "brandon-teta",
        role: "2D Artist",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/brandon-teta?trk=profile-badge"
    },
    {
        name: "Vanessa Palisoc",
        vanity: "vanessapalisoc",
        role: "2D Artist",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/vanessapalisoc?trk=profile-badge"
    },
    {
        name: "Elizabeth Gray",
        vanity: "elizabethagray1",
        role: "2D Artist",
        project: "Unreleased Fighter Game",
        link: "https://www.linkedin.com/in/elizabethagray1?trk=profile-badge"
    },
    /*
    {
        name: "Zenica Panos",
        vanity: "zenicapanos",
        role: "Lead Background Artist",
        project: "Buckethead 4",
        link: "https://www.linkedin.com/in/zenicapanos?trk=profile-badge"
    },
    */
];