export const menuData = [
    {
        title: "Games",
        link: "games",
        submenu: [
            {
                title: "Universica",
                link: "universica",
                img: "/assets/images/games/UniversicaIcon512.png"
            },
            {
                title: "Buckethead 4",
                link: "buckethead4",
                img: "/assets/images/games/Buckethead4Icon512.png"
            },
            {
                title: "Skyward",
                link: "skyward",
                img: "/assets/images/games/SkywardIcon512.png"
            },
            {
                title: "Forever After Death",
                link: "forever-after-death",
                img: "/assets/images/games/ForeverAfterDeathIcon512.png"
            }
        ]
    },
    {
        title: "Support",
        link: "support",
        submenu: [
            {
                title: "Contact Us",
                link: "contact-us"
            },
            {
                title: "FAQ",
                link: "faq"
            },
            {
                title: "Privacy Policy",
                link: "privacy"
            },
            {
                title: "Terms of Service",
                link: "terms-of-service"
            }
        ]
    },
    {
        title: "About",
        link: "about",
        submenu: [
            {
                title: "Careers",
                link: "careers"
            }
        ]
    }
];