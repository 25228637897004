import React, { Component } from 'react';
import IsMobile from '../../utils/isMobile';
import { Buckethead4GameDisplay, ForeverAfterDeathGameDisplay, SkywardGameDisplay, UniversicaGameDisplay } from '../../data/games-data';

export default class HomePage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2 style={{ textAlign: "center"}}>Studio Koleman</h2>
                <div style={{ display: "flex", justifyContent: "center"}}>
                    <div style={{ width: "100%", maxWidth: "1200px"}}>
                        <p style={{ textAlign: "left"}}>
                            We are an independent game development studio specialized in revitalizing retro game genres. 
                            We are dedicated to promoting healthy game design practices such as combating the industry standard of crunching.
                        </p>
                    </div>
                </div>
                <div>
                    <h4 style={{ textAlign: "center" }}>Check Out Our Games</h4>
                    <ul className={!IsMobile(window) ? "list-group d-flex list-group-horizontal" : "list-group d-flex list-group-vertical"} style={{ width: "100%", maxWidth: "1600px", display: "flex", justifyContent: "center"}}>
                        <li style={{ margin: "30px", marginBottom: "50px"}}>
                            <ul>
                                <li className="li-classic">
                                    <UniversicaGameDisplay displayIcon={false}/>
                                </li>
                                <li className="li-classic">
                                    <Buckethead4GameDisplay displayIcon={false}/>
                                </li>
                                <li className="li-classic">
                                    <SkywardGameDisplay displayIcon={false}/>
                                </li>
                                <li className="li-classic">
                                    <ForeverAfterDeathGameDisplay displayIcon={false}/>
                                </li>
                            </ul>
                        </li>
                        <li style={{ width: "100%", maxWidth: "600px" }}>
                            <a className="twitter-timeline" data-tweet-limit="3" data-theme="dark" href="https://twitter.com/StudioKoleman?ref_src=twsrc%5Etfw">Tweets by StudioKoleman</a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}