import BaseGamePage from "../components/pages/games/base-game-page";
import GameDisplay from "../components/layout/game-display";

export const UniversicaIcon = "./assets/images/games/UniversicaIcon512.png";
export const UniversicaBanner = "./assets/images/games/UniversicaBanner.png";
export const UniversicaBuild = "./assets/builds/Universica/";
export const UniversicaLinkItch = "https://mckoleman.itch.io/universica";
export const UniversicaLinkGoogle = "https://play.google.com/store/apps/details?id=com.StudioKoleman.Universica";
export const UniversicaLinkSteam = "";
export const UniversicaLinkApple = "";
export const UniversicaShortDesc = "Fight off endless hordes of enemies in this retro space shooter";
export const UniversicaLongDesc = "Relive the glory of classic arcade shooters in this fast-paced space shooter. Pick up powerups, wipe out waves of alien ships, and defeat epic bosses to see how long you can survive. \nLearn to defend Earth and fight off enemies with every type of powerup and several unique ships with unique abilities and playstyles. \nDodge and weave between lasers and learn the attack patterns of epic bosses to survive";

export const SkywardIcon = "./assets/images/games/SkywardIcon512.png";
export const SkywardBanner = "./assets/images/games/SkywardBanner.png";
export const SkywardBuild = "" //"./assets/builds/Skyward/index.html";
export const SkywardLinkItch = "https://mckoleman.itch.io/skyward";
export const SkywardLinkGoogle = "";
export const SkywardLinkSteam = "";
export const SkywardLinkApple = "";
export const SkywardShortDesc = "Climb Skyward through a procedural fortress as you recover your lost identity";
export const SkywardLongDesc = "Banished to the depths of Ziel's fortress, a lone Antek must battle hordes of enemies and reach the top of the fortress to reclaim his freedom and 'self.' No two playthroughs will be the same as each level is randomly generated, making each path a new one to explore. Use your magic to eradicate enemies and defeat powerful mini-bosses to uncover new levels. Are you able to ascend Ziel's fortress and vanquish him once and for all?";

export const ForeverAfterDeathIcon = "./assets/images/games/ForeverAfterDeathIcon512.png";
export const ForeverAfterDeathBanner = "./assets/images/games/ForeverAfterDeathBanner.png";
export const ForeverAfterDeathBuild = "" //"./assets/builds/ForeverAfterDeath/index.html";
export const ForeverAfterDeathLinkItch = "https://mckoleman.itch.io/forever-after-death";
export const ForeverAfterDeathLinkGoogle = "";
export const ForeverAfterDeathLinkSteam = "";
export const ForeverAfterDeathLinkApple = "";
export const ForeverAfterDeathShortDesc = "You are test subject. You are here for testing. You will take damage. You will break. You will repeat.";
export const ForeverAfterDeathLongDesc = "";

export const Buckethead4Icon = "./assets/images/games/Buckethead4Icon512.png";
export const Buckethead4Banner = "./assets/images/games/Buckethead4Banner.png";
export const Buckethead4Build = ""//"./assets/builds/Buckethead4/index.html";
export const Buckethead4LinkItch = "https://mckoleman.itch.io/buckethead4";
export const Buckethead4LinkGoogle = "";
export const Buckethead4LinkSteam = "";
export const Buckethead4LinkApple = "";
export const Buckethead4ShortDesc = "Face off against magnetic challenges in this atmospheric puzzle platformer.";
export const Buckethead4LongDesc = "Play the sequel to the critically acclaimed Buckethead 2, where Buckethead now has to face off against magnetic challenges in this atmospheric puzzle platformer. \nUse the power of magnetic attraction and repulsion to make your way through various levels and puzzles, and help Buckethead defeat Trius, the evil magnetic overlord!";

export const UniversicaGameDisplay = (displayIcon) => {
    return (
        <GameDisplay
            gameName="Universica"
            iconImg={UniversicaIcon}
            bannerImg={UniversicaBanner}
            linkLocation="/universica"
            displayIcon={displayIcon}
            shortDesc={UniversicaShortDesc}
        />);
}

export const SkywardGameDisplay = (displayIcon) => {
    return (
        <GameDisplay
            gameName="Skyward"
            iconImg={SkywardIcon}
            bannerImg={SkywardBanner}
            linkLocation="/skyward"
            displayIcon={displayIcon}
            shortDesc={SkywardShortDesc}
        />);
}

export const ForeverAfterDeathGameDisplay = (displayIcon) => {
    return (
        <GameDisplay
            gameName="Forever After Death"
            iconImg={ForeverAfterDeathIcon}
            bannerImg={ForeverAfterDeathBanner}
            linkLocation="/forever-after-death"
            displayIcon={displayIcon}
            shortDesc={ForeverAfterDeathShortDesc}
        />);
}

export const Buckethead4GameDisplay = (displayIcon) => {
    return (
        <GameDisplay
            gameName="Buckethead 4"
            iconImg={Buckethead4Icon}
            bannerImg={Buckethead4Banner}
            linkLocation="/buckethead4"
            displayIcon={displayIcon}
            shortDesc={Buckethead4ShortDesc}
        />);
}

export const UniversicaGamePage = () => {
    return (
        <BaseGamePage 
            gameTitle="Universica"
            gameIcon={UniversicaIcon}
            gameBanner={UniversicaBanner}
            gameBuild={UniversicaBuild}
            gameLinkItch={UniversicaLinkItch}
            gameLinkGoogle={UniversicaLinkGoogle}
            gameLinkSteam={UniversicaLinkSteam}
            gameLinkApple={UniversicaLinkApple}
            shortDesc={UniversicaShortDesc}
            longDesc={UniversicaLongDesc}
        />
    );
}

export const SkywardGamePage = () => {
    return (
        <BaseGamePage 
            gameTitle="Skyward"
            gameIcon={SkywardIcon}
            gameBanner={SkywardBanner}
            gameBuild={SkywardBuild}
            gameLinkItch={SkywardLinkItch}
            gameLinkGoogle={SkywardLinkGoogle}
            gameLinkSteam={SkywardLinkSteam}
            gameLinkApple={SkywardLinkApple}
            shortDesc={SkywardShortDesc}
            longDesc={SkywardLongDesc}
        />
    );
}

export const ForeverAfterDeathGamePage = () => {
    return (
        <BaseGamePage 
            gameTitle="Forever After Death"
            gameIcon={ForeverAfterDeathIcon}
            gameBanner={ForeverAfterDeathBanner}
            gameBuild={ForeverAfterDeathBuild}
            gameLinkItch={ForeverAfterDeathLinkItch}
            gameLinkGoogle={ForeverAfterDeathLinkGoogle}
            gameLinkSteam={ForeverAfterDeathLinkSteam}
            gameLinkApple={ForeverAfterDeathLinkApple}
            shortDesc={ForeverAfterDeathShortDesc}
            longDesc={ForeverAfterDeathLongDesc}
        />
    );
}

export const Buckethead4GamePage = () => {
    return (
        <BaseGamePage 
            gameTitle="Buckethead 4"
            gameIcon={Buckethead4Icon} 
            gameBanner={Buckethead4Banner}
            gameBuild={Buckethead4Build}
            gameLinkItch={Buckethead4LinkItch}
            gameLinkGoogle={Buckethead4LinkGoogle}
            gameLinkSteam={Buckethead4LinkSteam}
            gameLinkApple={Buckethead4LinkApple}
            shortDesc={Buckethead4ShortDesc}
            longDesc={Buckethead4LongDesc}
        />
    );
}