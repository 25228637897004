import React, { Component } from 'react';

export default class EmployeeCalendar extends Component {
    render() {
        return (
            <div style={{display: "flex", justifyContent: "center", alignContent: "center", marginBottom: "10px"}}>
                <div style={{ display: "block" }}>
                    <h1 style={{textAlign: "center"}}>Studio Koleman Events</h1>
                    <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", verticalAlign: "middle",
                        width: "820px", height: "620px", borderRadius: "15px", 
                        boxShadow: "5px 5px black", backgroundColor: "#002f06" }}>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showPrint=0&showCalendars=0&showNav=1&showTitle=0&src=OGZlODg2NWM3NDVjZmQ4NmM0Y2U2MzMzNDU1MTYwZDQ0MTNhOGEyOWM5YzM4MmUzYTc0ODU4NWE2ZjY0ZmIxNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23AD1457" 
                            style={{ marginTop: "10px", borderRadius: "10px" }} width="800" height="600" frameborder="0" scrolling="no">
                        </iframe>
                    </div>
                    <a href="https://calendar.google.com/calendar/u/0?cid=OGZlODg2NWM3NDVjZmQ4NmM0Y2U2MzMzNDU1MTYwZDQ0MTNhOGEyOWM5YzM4MmUzYTc0ODU4NWE2ZjY0ZmIxNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t"
                        style={{ marginTop: "10px", display: "flex", justifyContent: "center", textAlign: "center"}}>
                        Get access to calendar
                    </a>
                </div>
            </div>
        )
    }
}
