import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';

// Import components
import Navbar from "./components/layout/navbar";
import Footer from "./components/layout/footer";
import ScrollToTop from "./utils/scrollToTop";

// Import pages
import HomePage from "./components/pages/home-page";
import PrivacyPage from "./components/pages/privacy-page";
import ContactUsPage from "./components/pages/contact-us-page";
import TOSPage from './components/pages/tos-page';
import FAQPage from './components/pages/faq-page';
import GamesPage from './components/pages/games-page';
import AboutPage from './components/pages/about-page';
import SupportPage from './components/pages/support-page';
import CareersPage from './components/pages/careers-page';
import EmployeeCalendar from './components/pages/employee-calendar';

import UniversicaPage from './components/pages/games/universica';
import SkywardPage from './components/pages/games/skyward';
import ForeverAfterDeathPage from './components/pages/games/forever-after-death';
import Buckethead4Page from './components/pages/games/buckethead4';

// Import images
import bgImg from './images/starsBG.png';

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    
    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <div className="border-custom" style={{ 
      backgroundImage: `url(${bgImg})`,
      backgroundAttachment: "fixed",
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat-y'
    }}>
      <Router>
        <div className="container" style={{ backgroundColor: 'rgba(16, 16, 16, 0.8)', minHeight: "100vh", width: "100%", padding: "0px" }}>
          <ScrollToTop />
          <Navbar />
          <br />
          <div style={{ padding: "20px" }}>
            <Route path="/" exact component={HomePage} />
            <Route path="/home" exact component={HomePage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/terms-of-service" component={TOSPage} />
            <Route exact path="/faq" component={FAQPage} />
            <Route exact path="/contact-us" component={ContactUsPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/support" component={SupportPage} />
            <Route exact path="/careers" component={CareersPage} />

            <Route exact path="/games" component={GamesPage} />
            <Route exact path="/universica" component={UniversicaPage} />
            <Route exact path="/skyward" component={SkywardPage} />
            <Route exact path="/forever-after-death" component={ForeverAfterDeathPage} />
            <Route exact path="/buckethead4" component={Buckethead4Page} />
            <Route exact path="/employee/calendar" component={EmployeeCalendar}/>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
