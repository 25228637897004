import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IsMobile from '../../utils/isMobile';
import brandImg from "../../images/StudioKolemanName.png";

/* Aspect ratios of brands:
 * YouTube: 40/28 43/30 (1.417)
 * Twitter: 40/33 36/30 (1.215)
 * Instagram: 40/40 30/30 (1.000)
 * Facebook: 40/40 30/30 (1.000)
 * Discord: 40/36 27/30 (0.8875)
 * itchio: 40/36 33/30 (1.111)
 * LinkedIn: 40/37 32/30 (1.078)
 * Steam: 40/40 30/30 (1.012)
 * Apple: 125/42 111/37 (2.995)
 * Google Play: 125/37 111/33 (3.357)
 */


/* Steam component once page is ready
    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
        <a href="https://store.steampowered.com/publisher/studiokoleman" className="footer-link">
            <img alt="Studio Koleman Steam"
                src="./assets/images/brands/steamLogo.png"
                width="30px"
                height="30px"
                />
        </a>
    </li>

    Apple component once page is ready
    <li style={{ marginLeft: "auto", paddingRight: "5px" }}>
        <a href='https://apple.com' className="footer-link">
            <img alt='Download on the App Store' 
                src="./assets/images/brands/appleLogoWhite.png"
                width="111px"
                height="37px"/>
        </a>
    </li>
*/

// Component main
export default class Footer extends Component {
    render() {
        return (
            <div className="footer-bottom d-flex justify-content-center">
                <ul>
                    <li className="footer-item d-flex justify-content-center" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                        <Link to="/" className="footer-link">
                            <img width="163px"
                                height="23px"
                                src={brandImg}
                                style={{ position: "relative" }}
                                alt="Studio Koleman logo"
                            />
                        </Link>
                    </li>
                    <li className="footer-item">
                        <ul className={!IsMobile(window) ? "list-group d-flex list-group-horizontal" : "list-group d-flex list-group-vertical"}>
                            <li style={{ paddingLeft: "5px"}}>
                                <p className="default-text" style={{ padding: "0px"}}>External Pages</p>
                                <ul className="list-group list-group-horizontal d-flex">
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://youtube.com/@StudioKoleman" className="footer-link">
                                            <img alt="Studio Koleman YouTube"
                                                src="/assets/images/brands/youtubeLogoWhite.png"
                                                width="43px"
                                                height="30px"/>
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://studiokoleman.itch.io/" className="footer-link">
                                            <img alt="Studio Koleman itch.io"
                                                src="/assets/images/itchio/logos/itchio-logo-textless-white.png"
                                                width="33px"
                                                height="30px"
                                                />
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://twitter.com/StudioKoleman" className="footer-link">
                                            <img alt="Studio Koleman Twitter"
                                                src="/assets/images/brands/twitterLogoWhite.png"
                                                width="36px"    
                                                height="30px"/>
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://www.facebook.com/StudioKoleman/" className="footer-link">
                                            <img alt="Studio Koleman Facebook"
                                                src="/assets/images/brands/facebookLogo.png"
                                                width="30px"    
                                                height="30px"/>
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://www.instagram.com/studiokoleman/" className="footer-link">
                                            <img alt="Studio Koleman Instagram"
                                                src="/assets/images/brands/instagramLogo.png"
                                                width="30px"    
                                                height="30px"/>
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://www.linkedin.com/company/studiokoleman/" className="footer-link">
                                            <img alt="Studio Koleman LinkedIn"
                                                src="/assets/images/brands/linkedInLogo.png"
                                                width="32px"
                                                height="30px"
                                                />
                                        </a>
                                    </li>
                                    <li style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "0px", paddingBotton: "5px"}}>
                                        <a href="https://discord.gg/4ymAZdcYUc" className="footer-link">
                                            <img alt="Join Our Discord!"
                                                src="/assets/images/brands/discordLogoWhite.png"
                                                width="26px"
                                                height="30px"
                                                />
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li style={!IsMobile(window) ? {marginLeft: "auto", paddingRight: "5px"} : { paddingTop: "20px", paddingLeft: "10px" }}>
                                <a href='https://play.google.com/store/apps/dev?id=5119476421195583134&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className="footer-link">
                                    <img alt='Get it on Google Play' 
                                        src="/assets/images/brands/googlePlayLogo.png"
                                        width="125px"
                                        height="37px"/>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <br/>
                    <li className="footer-item">
                        <ul className={!IsMobile(window) ? "list-group d-flex list-group-horizontal" : "list-group d-flex list-group-vertical"}>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/" className="footer-link">Home</Link>
                            </li>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/privacy" className="footer-link">Privacy Policy</Link>
                            </li>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
                            </li>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/contact-us" className="footer-link">Contact Us</Link>
                            </li>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/careers" className="footer-link">Careers</Link>
                            </li>
                            <li className="footer-item" style={{ paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Link to="/faq" className="footer-link">FAQ</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="footer-item d-flex justify-content-center" style={{ paddingTop: "15px", paddingLeft: "10px", paddingRight: "10px", fontSize: "12px", color: "#FFFFFF" }}>
                        Copyright © StudioKoleman LLC 2021-2023. Google Play and the Google Play logo are trademarks of Google LLC.
                        TWITTER, TWEET, RETWEET and the Twitter Bird logo are trademarks of Twitter Inc. or its affiliates.
                    </li>
                </ul>
            </div>
        )
    }
}