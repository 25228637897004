import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MailComponent, SupportEmail } from '../../data/contact-info';

// Component main
export default class SupportPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2>Support</h2>
                <h4>Universica</h4>
                <p>
                    If you are having issues with Universica, visit the <a href="https://github.com/StudioKoleman/Universica">GitHub Issues Page</a><br/>
                    to directly submit a bug report or feature request.<br/>
                </p>
                <h4>Contact Us</h4>
                <p>At Studio Koleman we are dedicated to providing the highest quality gameplay experience possible.</p>
                <p>If you encounter any bugs, defects, or issues with any of our games and/or products, or are having trouble using any of our games and/or products, please reach out to us and let us know.</p>
                <p>Before contacting us, please review the <Link to="/faq">FAQ page</Link> for common problems and solutions to those problems.</p>
                <p>If you are still experiencing issues after reviewing the FAQ page, feel free to contact us via any of the methods listed. When you do, please include the name of the app, game, and/or product that you are experiencing difficulties with, as well as the version of that product (if applicable), a brief description of the issue, and </p>
                <p>You can contact us:</p>
                <ul>
                    <li>
                        <p>By email: {SupportEmail}</p>
                    </li>
                    <li>
                        <p>By visiting our <Link to="/contact-us">contact us</Link> page</p>
                    </li>
                    <li>
                        <p>By mail:</p>
                        <MailComponent/>
                    </li>
                </ul>
            </div>
        )
    }
}