import React, { Component } from 'react';
import { Buckethead4GameDisplay, ForeverAfterDeathGameDisplay, SkywardGameDisplay, UniversicaGameDisplay } from '../../data/games-data';

// Component main
export default class GamesPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2 style={{ textAlign: "center"}}>Games</h2>
                <h4 style={{ textAlign: "center"}}>Check out some of our games!</h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "100%", maxWidth: "650px"}}>
                        <ul className="ul-classic">
                            <li className="li-classic">
                                <UniversicaGameDisplay/>
                            </li>
                            <li className="li-classic">
                                <SkywardGameDisplay/>
                            </li>
                            <li className="li-classic">
                                <ForeverAfterDeathGameDisplay/>
                            </li>
                            <li className="li-classic">
                                <Buckethead4GameDisplay/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}