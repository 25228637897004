import React, { Component } from 'react';
import IsMobile from '../../../utils/isMobile';

export default class BaseGamePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gameTitle: props.gameTitle,
            gameIcon: props.gameIcon,
            gameBanner: props.gameBanner,
            gameBuild: props.gameBuild,
            gameLinkItch: props.gameLinkItch,
            gameLinkGoogle: props.gameLinkGoogle,
            gameLinkApple: props.gameLinkApple,
            gameLinkSteam: props.gameLinkSteam,
            shortDesc: props.shortDesc,
            longDesc: props.longDesc
        }
    }
    
    render() {
        return (
            <ul className="list-group d-flex list-group-vertical">
                {(this.state.gameBuild && !IsMobile(window)) ? (<li>
                    {/*<UnityApp build={this.state.gameBuild} gameName={this.state.gameName} width="1280" height="720"/>*/}
                    {
                    <iframe src={this.state.gameBuild + "index.html"}
                        style={{border: "0px", color: "#000000"}}
                        name={this.state.gameName}
                        title={this.state.gameName}
                        scrolling="no"
                        frameBorder="1"
                        marginHeight="20px" 
                        marginWidth="20px" 
                        height="800px" 
                        width="1280px">
                    </iframe>
                    }
                </li>) :(<></>)}
                <li style={{ display: "flex", justifyContent: "center", width: "100%"}}>
                    <div className="default-text" style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ width: "95%", maxWidth: "900px"}}>
                            <img style={{ width: "100%", height: "auto", maxWidth: "900px", margin: "auto", display: "flex", justifyContent: "center", paddingBottom: "0px", borderRadius: "30px"}} 
                                src={this.state.gameBanner} alt="Game banner"/>
                            <div style={{ paddingTop: "20px", paddingBottom: "0px" }}>
                                {this.state.shortDesc ? (
                                    <div>
                                        <h2 style={{ textAlign: "left"}}>{this.state.shortDesc}</h2><br/>
                                        <h4 style={{ textAlign: "left"}}>{this.state.longDesc ? this.state.longDesc : ""}</h4>
                                        <br/>
                                    </div>
                                ) : (<></>)}
                                <h3 style={{ textAlign: "center", fontSize: "30px", paddingBottom: "10px"}}>Download {this.state.gameTitle}:</h3>
                                <ul className={!IsMobile(window) ? "list-group d-flex list-group-horizontal" : "list-group d-flex list-group-vertical"}
                                    style={{ padding: "20px", display: "flex", justifyContent: "center"}}>
                                    <ConditionalLink link={this.state.gameLinkGoogle} linkText="Google Play"/>
                                    <ConditionalLink link={this.state.gameLinkApple} linkText="Apple App Store"/>
                                    <ConditionalLink link={this.state.gameLinkItch} linkText="itch.io"/>
                                    <ConditionalLink link={this.state.gameLinkSteam} linkText="Steam"/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        )
    }
}

const ConditionalLink = props => {
    const googleImgSrc = './assets/images/brands/googlePlayLogo.png';
    const googleImgAlt = 'Get it on Google Play';
    const itchImgSrc = './assets/images/itchio/badges/badge-color.png';
    const itchImgAlt = 'Download from itch.io';

    let imageAlt = "";
    let imageSrc = "";
    let imageWidth = "";
    let imageHeight = "";

    // Set Google Play sources
    if(props.linkText === "Google Play") {
        imageAlt = googleImgAlt;
        imageSrc = googleImgSrc;
        imageWidth = "240px";
        imageHeight = "71px";
    }
    
    // Set itch.io sources
    if(props.linkText === "itch.io") {
        imageAlt = itchImgAlt;
        imageSrc = itchImgSrc;
        imageWidth = "240px";
        imageHeight = "74px";
    }

    if(props.link !== "") {
        return (<li style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "15px"}}>
            <a href={props.link}>
                <img alt={imageAlt} 
                    src={imageSrc}
                    width={imageWidth}
                    height={imageHeight}/>
            </a><br/>
        </li>);
    }
    else {
        return (<></>);
    }
}