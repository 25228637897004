import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Component main
export default class GameDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            gameName: props.gameName,
            iconImg: props.iconImg,
            bannerImg: props.bannerImg,
            linkLocation: props.linkLocation,
            shortDesc: props.shortDesc,
            longDesc: props.longDesc,
            displayIcon: props.displayIcon
        };
    }

    render() {
        return (
            <div>
                <ul>
                    <li className="container" style={{ display: "flex", justifyContent: "center", paddingTop: "30px", maxWidth: "100%"}}>
                        {this.state.bannerImg ? (
                            <Link to={this.state.linkLocation} className="footer-link">
                                <div className="gamedisplay-img-overlay" style={{ width: "100%", height: "auto", maxWidth: "600px", display: "flex", justifyContent: "center", paddingBottom: "10px"}}>
                                    <img className="gamedisplay-img-back"
                                        src={this.state.bannerImg}
                                        style={{ width: "100%", height: "auto", maxWidth: "512px", position: "relative", borderRadius: "20px"}}
                                        alt="Game background"
                                        />
                                    {(this.state.iconImg && this.state.displayIcon) ? (                                    
                                    <img className="gamedisplay-img-top"
                                        src={this.state.iconImg}
                                        style={{ 
                                            width: "100px", maxWidth: "20%", height: "auto", 
                                            position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", borderRadius: "20px" }}
                                        alt="Game icon"
                                    />
                                    ) : (<></>)}
                                </div>
                            </Link>
                        ) : (<></>)}
                    </li>
                    {this.state.shortDesc ? (
                    <li style={{ paddingLeft: "40px", textAlign: "left", fontSize: "18px"}}>
                        <p>{this.state.shortDesc}</p>
                    </li>
                    ) : (<></>)}
                    {this.state.longDesc ? (
                    <li className="d-flex justify-content-center">
                        <p>{this.state.longDesc}</p>
                    </li>
                    ) : (<></>)}
                </ul>
            </div>
        )
    }
}