import Dropdown from "./dropdown.js";
import { useState, useEffect, useRef } from "react";
import IsMobile from "../../utils/isMobile.js";

const MenuItems = ({items, depthLevel}) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        // Subscribe listeners
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        // Unsubscribe listeners
        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    });

    const onMouseEnter = () => {
        !IsMobile(window) && setDropdown(true);
    };

    const onMouseLeave = () => {
        !IsMobile(window) && setDropdown(false);
    };

    function mobileDropdown(items) {
        const itemsCopy = JSON.parse(JSON.stringify(items));
        itemsCopy.submenu.unshift({});
        itemsCopy.submenu[0].title = itemsCopy.title;
        itemsCopy.submenu[0].link = itemsCopy.link;
        return itemsCopy;
    }

    return (
        <li className={depthLevel > 0 ? "menu-items-fill" : "menu-items"}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {items.submenu ? (
                <>
                    {!IsMobile(window) ? (
                        <>
                            <button type="button"
                                style={{ minHeight: "80px" }}
                                aria-haspopup="menu" 
                                aria-expanded={dropdown ? "true" : "false"}>
                                <a href={items.link}>
                                    {items.title}
                                    {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow"/>}
                                </a>
                            </button>
                            <Dropdown dropdown={dropdown} submenus={items.submenu} depthLevel={depthLevel}/>
                        </>
                    ) : (
                        <>
                            <button type="button" 
                                style={{ minHeight: "80px" }}
                                aria-haspopup="menu" 
                                aria-expanded={dropdown ? "true" : "false"}
                                onClick={() => setDropdown((prev) => !prev)}>
                                {items.title}{" "}
                                {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow"/>}
                            </button>
                            <Dropdown dropdown={dropdown} submenus={mobileDropdown(items).submenu} depthLevel={depthLevel}/>
                        </>
                    )}
                </>
            ) : (
                <>
                    {items.img ? (
                        <button type="button" style={{ minHeight: "80px" }}>
                            <a href={items.link}>
                                <img width="50px"
                                    height="50px"
                                    src={items.img}
                                    style={{ position: "relative", marginRight: "10px", borderRadius: "10px" }}
                                    alt="Game icon"/>
                                {items.title}
                            </a>
                        </button>
                    ) : (
                        <button type="button" style={{ minHeight: "80px" }}>
                            <a href={items.link}>{items.title}</a>
                        </button>
                    )}
                </>
            )}
        </li>
    );
};

export default MenuItems;