import React, { Component } from 'react';

// Component main
export default class CareersPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2>Careers</h2>
                <h4>Apply to Studio Koleman</h4>
                <p>
                    Our team is always growing and looking for new talent to join us.<br/>
                    We especially love giving students their first opportunities to work in the industry.<br/>

                    You can apply using our <a href="https://forms.gle/wckMCybQgTKnNA3c8">Application Form</a>.
                </p>
                <h4>Currently Open Positions</h4>
                <p>
                The following full-time and/or part-time positions are currently open:<br/>
                </p>
                <h5><b>Operations</b></h5>
                <p>
                    - Project Manager/Lead Producer<br/>
                    - Marketing and Advertising Director<br/>
                </p>
                <h5><b>Engineering</b></h5>
                <p>
                    - Lead Programmer<br/>
                    - Senior Programmer<br/>
                    - Junior Programmer<br/>
                    - Network Programmer<br/>
                    - Web Developer<br/>
                </p>
                <h5><b>Art</b></h5>
                <p>
                    - Graphic Designer<br/>
                    - 2D Pixel Artist/Animator<br/>
                    - Sound Designer/Musician<br/>
                </p>
            </div>
        )
    }
}