export const SupportEmail = "support@studiokoleman.com";
export const BusinessAddress = "223 NW 29th St, Gainesville, FL 32607";

export const MailComponent = () => {
    return (
        <p>
            Studio Koleman<br/>
            223 NW 29th St<br/>
            Gainesville, FL 32607<br/>
            United States<br/>
        </p>
    );
}