import React, { Component } from 'react';
import { linkedIns } from '../../data/employees-info';

// Component main
export default class AboutPage extends Component {
    render() {
        return (
            <div className="default-text" style={{ paddingBottom: "50px" }}>
                <h2>About Us</h2>
                <h4>Our Goals</h4>
                <p>
                    Studio Koleman was founded on three fundamentals:<br/>
                    - Make games that are fun.<br/>
                    - Make games that don't take advantage of the players in any way.<br/>
                    - Make games in a way that respects the desires and health of both the developers and the players.<br/>
                </p>
                <p>
                    We are constantly striving to improve all of our games and products and are always working on new projects in the background.<br/>
                    While doing this, we always look back to our three fundamentals to make sure the needs of both developers and players are always met.<br/>
                </p>
                <br/>

                <h4>Origins</h4>
                <p>
                    Studio Koleman started in a dorm room at the University of Florida in Gainesville in 2021, 
                    when a bored college student, <a href="https://koleman.dev">Kristian Kolehmainen</a>, 
                    wanted to start taking game creation seriously.<br/>
                    Since then we have released 4 games on <a href="https://studiokoleman.itch.io">itch.io</a>,
                    1 game and 1 side project on <a href="https://play.google.com/store/apps/dev?id=5119476421195583134&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">Google Play</a>, 
                    and have started work on 2 long-term projects as well as several game demos to find out what we would like to make that people would enjoy playing.<br/>
                </p>
                <br/>

                <h4>The Future</h4>
                <p>
                    We are focusing most of our effor on developing a retro fighting game. We will post more details about the game as it nears the closed alpha.<br/>
                    We are also working on porting all of our newer games to mobile and publishing them on Google Play and the Apple App Store.<br/>
                    We are also working on publishing some of our games on Steam.<br/>
                    We are also working on 2 long-term projects, Project Eternal Rain, and Project Boston, which we will post more information about such as potential release dates when they approach alpha testing.<br/>
                    Finally, we are beginning work on a short term project that will be finished by December 2022, and will be released by February 2023.<br/>
                    Stay on the look-out for some of these new projects and follow our <a href="https://twitter.com/StudioKoleman">Twitter</a> to stay up to date with what we are doing!<br/>
                </p>
                <br/>

                <h4>Meet Our Team</h4>
                <p>

                </p>
                <ul className="container-fluid">
                    <li className="row">
                    {linkedIns.map((info) => (
                        <div key={info.name} className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 badge-base LI-profile-badge" 
                            style={{ marginTop: "10px" }}
                            data-locale="en_US" data-size="medium" data-theme="dark" data-type="VERTICAL" data-vanity={info.vanity} data-version="v1">
                            <div>
                                <h5>
                                    <b>{info.name}</b><br/>
                                    {info.role}<br/>
                                    {info.project}
                                </h5>
                                <a className="badge-base__link LI-simple-link" href={info.link} style={{ lineHeight: "0px", fontSize: "0px", color: 'transparent' }}>
                                    {info.name}
                                </a>
                            </div>
                        </div>
                    ))}
                    </li>
                </ul>
                <br/>
            </div>
        )
    }
}